.ck-radio.no-margin {
  margin: 0;
}
.ck-radio .ant-form-item-row {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: stretch;
  width: 100%;
}
.ck-radio .ant-form-item-row .ant-col {
  width: 100%;
  text-align: left;
}
.ck-radio label,
.ck-radio .ant-form-item-extra {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  height: 26px;
}
.ck-radio .ant-radio-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: flex-start;
  align-items: flex-start;
}
.ck-radio .ant-radio-wrapper .ant-radio {
  transform: scale(1.3);
}
.ck-radio .ant-radio-wrapper .ant-radio-inner {
  border-color: var(--primary-color);
  border-width: 2px;
}
.ck-radio .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-inner {
  border-color: var(--primary-color);
}
.ck-radio
  .ant-radio-wrapper.ant-radio-wrapper-checked
  .ant-radio-checked
  .ant-radio-inner::after {
  background-color: var(--mariner-color);
  transform: scale(0.6);
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  margin-left: -8px;
}
.ck-radio .ant-radio-wrapper span:not(.ant-radio) {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

/* ==========================================================================
  Orientation
  ========================================================================== */
/* ==========================================================================
  Orientation
  "row" | "column" | "row-reverse" | "column-reverse"
  ========================================================================== */
.ck-radio.orientation-row .ant-radio-group {
  flex-direction: row !important;
  align-items: center;
  justify-content: space-between;
}
.ck-radio.orientation-column .ant-radio-group {
  flex-direction: column !important;
}
.ck-radio.orientation-row-reverse .ant-radio-group {
  flex-direction: row-reverse !important;
  align-items: center;
  justify-content: space-between;
}
.ck-radio.orientation-column-reverse
  .ant-radio-group {
  flex-direction: column-reverse !important;
}
@media (min-width: 769px) {
  .ck-radio.orientation-desktop-row
    .ant-radio-group {
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between;
  }
  .ck-radio.orientation-desktop-column
    .ant-radio-group {
    flex-direction: column !important;
  }
  .ck-radio.orientation-desktop-row-reverse
    .ant-radio-group {
    flex-direction: row-reverse !important;
    align-items: center;
    justify-content: space-between;
  }
  .ck-radio.orientation-desktop-column-reverse
    .ant-radio-group {
    flex-direction: column-reverse !important;
  }
}
@media (max-width: 768px) {
  .ck-radio.orientation-responsive-row
    .ant-radio-group {
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between;
  }
  .ck-radio.orientation-responsive-column-reverse
    .ant-radio-group {
    flex-direction: column !important;
  }
  .ck-radio.orientation-responsive-row-reverse
    .ant-radio-group {
    flex-direction: row-reverse !important;
    align-items: center;
    justify-content: space-between;
  }
  .ck-radio.orientation-responsive-column-reverse
    .ant-radio-group {
    flex-direction: column-reverse !important;
  }
}
