/* ==========================================================================
  EC offsets
  ========================================================================== */
  .ck-modal .ant-modal {
    width: 95% !important;
    max-width: 720px;
    margin: 0 auto;
    top: var(--offset-desktop);
  }
  @media (max-width: 992px) {
    .ck-modal .ant-modal {
      top: var(--offset-mobile);
    }
  }
  
  /* ==========================================================================
      WS offsets
      ========================================================================== */
  .ws-app .ck-modal .ant-modal {
    top: var(--offset-navbar-desktop);
  }
  @media (max-width: 992px) {
    .ws-app .ck-modal .ant-modal {
      top: var(--offset-navbar-mobile);
    }
  }
  
  .ck-modal .ant-modal .ant-modal-content {
    border-radius: 10px;
    background: var(--white) !important;
  }
  .ck-modal .ant-modal .ant-modal-body {
    padding: 40px 60px;
  }
  .ck-modal .ant-modal .ant-modal-close,
  .ck-modal .ant-modal .ant-modal-close .ant-modal-close-x {
    width: 20px;
    height: 22px;
    line-height: 22px;
    top: 20px;
    right: 20px;
  }
  .ck-modal .ant-modal .ant-modal-close-x svg {
    width: 20px;
    height: auto;
    color: #000;
  }
  .ck-modal .ant-modal .modal-title {
    color: var(--mariner-color);
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
  }
  .ck-modal .ant-modal .header-icon-container {
    width: 100%;
  }
  .ck-modal .ant-modal .header-icon-container .anticon {
    margin: 0 auto 15px auto;
    display: block;
    color: var(--mariner-color);
  }
  .ck-modal .ant-modal .actions-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
  .ck-modal .ant-modal .actions-container.column-force {
    flex-direction: column;
  }
  @media (max-width: 768px) {
    .ck-modal .ant-modal {
      width: 320px !important;
    }
    .ck-modal .ant-modal .ant-modal-close,
    .ck-modal .ant-modal .ant-modal-close .ant-modal-close-x {
      top: 16px;
      right: 16px;
    }
    .ck-modal .ant-modal .ant-modal-body {
      padding: 40px 16px;
    }
    .ck-modal .ant-modal .actions-container {
      flex-direction: column;
      align-items: center;
    }
  }
  
  /* ==========================================================================
      Orientation
      "row" | "column" | "row-reverse" | "column-reverse" | "triangle" | "triangle-reverse";
      ========================================================================== */
  .ck-modal .ant-modal .actions-container.orientation-row {
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between;
  }
  .ck-modal .ant-modal .actions-container.orientation-column {
    flex-direction: column !important;
  }
  .ck-modal .ant-modal .actions-container.orientation-row-reverse {
    flex-direction: row-reverse !important;
    align-items: center;
    justify-content: space-between;
  }
  .ck-modal .ant-modal .actions-container.orientation-column-reverse {
    flex-direction: column-reverse !important;
  }
  .ck-modal .ant-modal .actions-container.orientation-triangle {
    flex-direction: row !important;
    flex-wrap: wrap !important;
    justify-content: space-between !important;
    gap: 20px;
  }
  .ck-modal
    .ant-modal
    .actions-container.orientation-triangle
    .ant-btn:last-child {
    margin: 0 auto;
  }
  @media (min-width: 769px) {
    .ck-modal .ant-modal .actions-container.orientation-desktop-row {
      flex-direction: row !important;
      align-items: center;
      justify-content: space-between;
    }
    .ck-modal .ant-modal .actions-container.orientation-desktop-column {
      flex-direction: column !important;
    }
    .ck-modal .ant-modal .actions-container.orientation-desktop-row-reverse {
      flex-direction: row-reverse !important;
      align-items: center;
      justify-content: space-between;
    }
    .ck-modal .ant-modal .actions-container.orientation-desktop-column-reverse {
      flex-direction: column-reverse !important;
    }
    .ck-modal .ant-modal .actions-container.orientation-desktop-triangle {
      flex-direction: row !important;
      flex-wrap: wrap !important;
      justify-content: space-between !important;
      gap: 20px;
    }
    .ck-modal
      .ant-modal
      .actions-container.orientation-desktop-triangle
      .ant-btn:last-child {
      margin: 0 auto;
    }
  }
  @media (max-width: 768px) {
    .ck-modal .ant-modal .actions-container.orientation-responsive-row {
      flex-direction: row !important;
      align-items: center;
      justify-content: space-between;
    }
    .ck-modal
      .ant-modal
      .actions-container.orientation-responsive-column-reverse {
      flex-direction: column !important;
    }
    .ck-modal .ant-modal .actions-container.orientation-responsive-row-reverse {
      flex-direction: row-reverse !important;
      align-items: center;
      justify-content: space-between;
    }
    .ck-modal
      .ant-modal
      .actions-container.orientation-responsive-column-reverse {
      flex-direction: column-reverse !important;
    }
    .ck-modal .ant-modal .actions-container.orientation-triangle .ant-btn,
    .ck-modal
      .ant-modal
      .actions-container.orientation-desktop-triangle
      .ant-btn {
      margin: 0 auto;
    }
  }
  
  /* ==========================================================================
      Order
      ========================================================================== */
  .ck-modal .ant-modal .actions-container .order-1 {
    order: 1;
  }
  .ck-modal .ant-modal .actions-container .order-2 {
    order: 2;
  }
  .ck-modal .ant-modal .actions-container .order-3 {
    order: 3;
  }
  @media (min-width: 769px) {
    .ck-modal .ant-modal .actions-container .order-desktop-1 {
      order: 1;
    }
    .ck-modal .ant-modal .actions-container .order-desktop-2 {
      order: 2;
    }
    .ck-modal .ant-modal .actions-container .order-desktop-3 {
      order: 3;
    }
  }
  @media (max-width: 768px) {
    .ck-modal .ant-modal .actions-container .order-responsive-1 {
      order: 1;
    }
    .ck-modal .ant-modal .actions-container .order-responsive-2 {
      order: 2;
    }
    .ck-modal .ant-modal .actions-container .order-responsive-3 {
      order: 3;
    }
  }
  
  /* ==========================================================================
      Themes
      ========================================================================== */
  .ck-modal.delete-theme .ant-modal .modal-title {
    color: var(--error-color);
  }
  