.ck-navigation-button {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  gap: 5px;
  padding: 0 2px;
  border: 0;
  box-shadow: none;
  height: 24px;
}
.ck-navigation-button span {
  color: #000;
}
.ck-navigation-button span:not(.anticon) {
  font-size: 12px;
  line-height: 22px;
  margin: 0;
}
.ck-navigation-button .anticon.caret-up svg,
.ck-navigation-button .anticon.caret-down svg,
.ck-navigation-button .anticon.arrow-left svg,
.ck-navigation-button .anticon.arrow-right svg {
  width: 20px;
  height: auto;
}
.ck-navigation-button .anticon.anticon-arrow-left {
  height: 14px;
}
