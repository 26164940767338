.ck-input-counter.no-margin {
  margin: 0;
}
.ck-input-counter input {
  width: 100%;
  height: 40px;
  border: 1px solid var(--gray-200);
  border-radius: 0 !important;
  color: var(--primary-color);
  text-align: center !important;
  font-size: 16px !important;
  font-weight: 600;
}
.ck-input-counter .ant-form-item-row {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: stretch;
  width: 100%;
}
.ck-input-counter .ant-form-item-row .ant-col {
  width: 100%;
  text-align: left;
}
.ck-input-counter label {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  height: 26px;
}
.ck-input-counter input::placeholder {
  text-align: center !important;
  color: var(--gray-300) !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.ck-input-counter .ant-form-item-control-input-content,
.ck-input-counter .input-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.ck-input-counter .ant-input-number-affix-wrapper,
.ck-input-counter .ant-input-number {
  padding: 0;
  width: 60px;
}
.ck-input-counter .ant-form-item-control-input-content {
  gap: 5px;
}
.ck-input-counter .input-container .ant-btn {
  border: 1px solid var(--primary-color);
  background: var(--primary-color);
  height: 40px;
  width: 28px;
  padding: 0;
  text-align: center;
}
.ck-input-counter .input-container .ant-btn span {
  color: var(--white);
  font-size: 32px;
  height: 40px;
  line-height: 32px;
  width: 28px;
}
.ck-input-counter .input-container .ant-btn:first-child {
  border-radius: 10px 0px 0px 10px;
}
.ck-input-counter .input-container .ant-btn:last-child {
  border-radius: 0px 10px 10px 0px;
}
.ck-input-counter .units {
  margin: 0;
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
}

.ck-input-counter input::placeholder {
  text-align: left !important;
  color: var(--gray) !important;
}
.ck-input-counter .ant-input-affix-wrapper {
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 0px;
  overflow: hidden;
  height: 40px;
  width: 60px;
}
.ck-input-counter .ant-input-clear-icon,
.ck-input-counter .ant-input-clear-icon svg {
  height: 18px;
  width: auto;
}
.ck-input-counter .ant-form-item-explain {
  margin-top: 5px;
  color: var(--white);
  font-size: 16px;
  line-height: 20px;
}
.ck-input-counter .ant-input-suffix svg {
  height: 18px !important;
  width: auto !important;
}
.ck-input-counter .ant-form-item-control-input-content,
.ck-input-counter .ant-input-number {
  border-radius: 0 !important;
}
