.ck-input-number input {
  width: 100%;
  color: var(--gray);
  font-size: 16px;
}
.ck-input-number .ant-input-number-affix-wrapper,
.ck-input-number .ant-input-number {
  width: 100%;
}
.ck-input-number .ant-form-item-row {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: stretch;
  width: 100%;
}
.ck-input-number .ant-form-item-row .ant-col {
  width: 100%;
  text-align: left;
}
.ck-input-number .ant-form-item-explain div {
  color: #666;
  font-size: 12px;
  line-height: 15px;
  color: var(--gray-450);
}
.ck-input-number .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  margin-right: 0;
  color: var(--text-color);
}
.ck-input-number .ant-input-number-handler-wrap {

}
.ck-input-number .ant-input-number-handler-wrap .ant-input-number-handler {
  height: 15px !important;
  /* border: 1px solid #fff; */
  border: unset;
  background: var(--primary-color);
  border-radius: 0;
  /* display: flex; */
}
.ck-input-number .ant-input-number-handler-wrap .ant-input-number-handler {
  height: 15px !important;
}
.ck-input-number .ant-input-number-handler-wrap .ant-input-number-handler .anticon {
  color: #fff;
}
.ck-input-number .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
.ck-input-number .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
  width: 14px;
}
.ck-input-number .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down {
  border-top: 1px solid #fff;
}
.ck-input-number .ant-input-number-handler-wrap .ant-input-number-handler:hover {
  background-color: var(--mariner-color);
}
/* ==========================================================================
  Default
  ========================================================================== */
.ck-input-number.default {

}
.ck-input-number.default label,
.ck-input-number.default .ant-form-item-extra {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 600 !important;
  line-height: 26px;
  height: 26px;
}
.ck-input-number.default .ant-input-number-affix-wrapper {
  padding-left: 0;
  padding-right: 0;
  border-radius: 0 !important;
  border: 0;
}
.ck-input-number.default .ant-input-number.ant-input-number-in-form-item {
  padding-left: 0;
  padding-right: 0;
  border-radius: 0 !important;
  border: 0;
  border-bottom: 1px solid var(--text-color);
}
.ck-input-number.default .ant-input-number:focus, 
.ck-input-number.default .ant-input-number-focused {
  box-shadow: none;
}
.ck-input-number.default input {
  padding: 0;
}
.ck-input-number.default input::placeholder {
  text-align: left !important;
  color: var(--gray-300) !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px; 
}
.ck-input-number.default .ant-input-affix-wrapper {
  padding-left: 0;
  padding-right: 0;
  border: 0;
  border-bottom: 1px solid var(--text-color);
  border-radius: 0px !important;
}
.ck-input-number.default .ant-input-affix-wrapper input {
  border: 0 !important;
}


/* ==========================================================================
  Rounded
  ========================================================================== */
.ck-input-number.rounded {
  color: #fff;
}
.ck-input-number.rounded label,
.ck-input-number.rounded .ant-form-item-extra {
  color: #fff;
  font-weight: 600 !important;
  font-weight: 600;
  line-height: 26px;
  height: 26px;
}
.ant-input-number-affix-wrapper {
  border-radius: 8px;
  background: var(--white);
  text-align: left;
  overflow: hidden;
}
.ck-input-number.rounded input {
  width: 100%;
  height: 44px;
  border-radius: 8px;
  background: var(--white);
  text-align: left;
}
.ck-input-number.rounded input::placeholder {
  text-align: left !important;
  color: var(--gray) !important;
}
.ck-input-number.rounded .ant-input-affix-wrapper {
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
}
.ck-input-number.rounded .ant-input-clear-icon,
.ck-input-number.rounded .ant-input-clear-icon svg {
  height: 18px;
  width: auto;
}
.ck-input-number.rounded .ant-form-item-explain {
  margin-top: 5px;
  color: var(--white);
  font-size: 16px;
  line-height: 20px;
}
.ck-input-number.rounded .ant-input-suffix svg {
  height: 18px !important;
  width: auto !important;
}
.ck-input-number.rounded .ant-form-item-explain div,
.ck-input-number.rounded .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  color: #fff;
}
.ck-input-number.rounded .ant-input-number-handler-wrap .ant-input-number-handler,
.ck-input-number.rounded .ant-input-number-handler-wrap:hover .ant-input-number-handler {
  height: 22px !important;
}

