/* ==========================================================================
  Base styles
  ========================================================================== */
.ck-link {
  width: auto;
  min-height: 40px;
  border-radius: 8px !important;
  transition: all 0.5s ease;
  padding: 5px 16px;
  text-align: center;
}

/* With buttons */
.ck-link.iconed {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.ck-link.iconed span {
  margin: 0;
}

/* Underlined */
.ck-link.underlined,
.ck-link.underlined span:not(.anticon) {
  text-decoration: underline;
}

/* Cicled */
.ck-link.size-circle {
  border-radius: 50% !important;
}

.ck-link,
.ck-link span:not(.anticon) {
  font-size: 16px !important;
  line-height: 30px;
  font-weight: 400;
}
.ck-link span.anticon svg {
  height: 24px;
  width: auto;
}
.ck-link.variant-link,
.ck-link.variant-text {
  padding: 0;
  min-height: 25px;
  height: auto;
}

/* ant-btn-block */
.ck-link.block {
  width: 240px;
  display: inline-block;
}

/* large button */
.ck-link.size-large,
.ck-link.size-large span {
  font-weight: 600;
}

/* small button */
.ck-link.size-small {
  min-height: 24px;
  padding: 1px 8px;
}
.ck-link.size-small,
.ck-link.size-small span:not(.anticon) {
  font-size: 14px !important;
  line-height: 22px !important;
}
.ck-link.size-small span.anticon {
  height: 22px;
}
.ck-link.size-small span.anticon svg {
  height: 22px;
  width: auto;
}
.ck-link.size-small.block {
  width: 240px;
}

.ck-link.variant-link,
.ck-link.variant-link span:not(.anticon) {
  text-decoration: underline;
}

/* ==========================================================================
  Icon only
  ========================================================================== */
.ck-link.icon-only {
  width: 36px;
  height: 36px;
  padding: 5px;
}
.ck-link.icon-only svg {
  width: 26px;
  height: auto;
}


/* ==========================================================================
  Primary color
  ========================================================================== */
/*
  Primary color - default button 
  ========================================================================== */
.ck-link.variant-primary.color-primary {
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.ck-link.variant-primary.color-primary,
.ck-link.variant-primary.color-primary span {
  color: #fff !important;
}

/* Active */
.ck-link.variant-primary.color-primary:not([disabled]):focus {
  background: var(--mariner-dark-color) !important;
  border-color: var(--mariner-dark-color) !important;
  color: #fff !important;
}
.ck-link.variant-primary.color-primary:not([disabled]):focus span {
  color: #fff !important;
}

/* Hover */
.ck-link.variant-primary.color-primary:not([disabled]):hover,
.ck-link.variant-primary.color-primary:not([disabled]):active {
  background: var(--mariner-color) !important;
  border-color: var(--mariner-color) !important;
  color: #fff !important;
}
.ck-link.variant-primary.color-primary:not([disabled]):hover span,
.ck-link.variant-primary.color-primary:not([disabled]):active span {
  color: #fff;
}

/* Disabled */
.ck-link.variant-primary.color-primary[disabled] {
  border: rgba(var(--primary-color-rgb), 0.5) !important;
  background: rgba(var(--primary-color-rgb), 0.5) !important;
  color: #fff;
}
.ck-link.variant-primary.color-primary[disabled] span {
  color: #fff;
}

/*
Primary color - primary button - dashed
========================================================================== */
.ck-link.variant-secondary.color-primary {
  background: transparent;
  border: 1px solid var(--primary-color);
}
.ck-link.variant-dashed.color-primary {
  background: transparent;
  border: 1px dashed var(--primary-color);
}
.ck-link.variant-secondary.color-primary,
.ck-link.variant-secondary.color-primary span,
.ck-link.variant-dashed.color-primary,
.ck-link.variant-dashed.color-primary span {
  color: var(--primary-color);
}

/* Active */
.ck-link.variant-secondary.color-primary:not([disabled]):focus,
.ck-link.variant-dashed.color-primary:not([disabled]):focus {
  border-color: var(--mariner-dark-color) !important;
  color: var(--mariner-dark-color) !important;
}
.ck-link.variant-secondary.color-primary:not([disabled]):focus span,
.ck-link.variant-dashed.color-primary:not([disabled]):focus span {
  color: var(--mariner-dark-color) !important;
}

 /* Hover */
.ck-link.variant-secondary.color-primary:not([disabled]):hover,
.ck-link.variant-secondary.color-primary:not([disabled]):active,
.ck-link.variant-dashed.color-primary:not([disabled]):hover,
.ck-link.variant-dashed.color-primary:not([disabled]):active {
  border-color: var(--mariner-color) !important;
  color: var(--mariner-dark-color) !important;
}
.ck-link.variant-secondary.color-primary:not([disabled]):hover span,
.ck-link.variant-secondary.color-primary:not([disabled]):active span,
.ck-link.variant-dashed.color-primary:not([disabled]):hover span,
.ck-link.variant-dashed.color-primary:not([disabled]):active span {
  color: var(--mariner-color) !important;
}

/* Disabled */
.ck-link.variant-secondary.color-primary[disabled],
.ck-link.variant-dashed.color-primary[disabled] {
  border-color: rgba(var(--primary-color-rgb), 0.5) !important;
  color: rgba(var(--primary-color-rgb), 0.5) !important;
}
.ck-link.variant-secondary.color-primary[disabled] span,
.ck-link.variant-dashed.color-primary[disabled] span {
  color: rgba(var(--primary-color-rgb), 0.5) !important;
}
/*
  Primary color - link button   text button 
  ========================================================================== */
.ck-link.variant-text.color-primary,
.ck-link.variant-text.color-primary span,
.ck-link.variant-link.color-primary,
.ck-link.variant-link.color-primary span {
  color: var(--primary-color);
}

/* Active */
.ck-link.variant-text.color-primary:not([disabled]):focus,
.ck-link.variant-text.color-primary:not([disabled]):focus span,
.ck-link.variant-link.color-primary:not([disabled]):focus,
.ck-link.variant-link.color-primary:not([disabled]):focus span {
  color: var(--mariner-dark-color) !important;
}
/* Hover */
.ck-link.variant-link.color-primary:not([disabled]):hover,
.ck-link.variant-link.color-primary:not([disabled]):hover span,
.ck-link.variant-link.color-primary:not([disabled]):active,
.ck-link.variant-link.color-primary:not([disabled]):active span,
.ck-link.variant-text.color-primary:not([disabled]):hover,
.ck-link.variant-text.color-primary:not([disabled]):hover span,
.ck-link.variant-text.color-primary:not([disabled]):active,
.ck-link.variant-text.color-primary:not([disabled]):active span {
  color: var(--mariner-color) !important;
}

/* Disabled */
.ck-link.variant-link.color-primary[disabled],
.ck-link.variant-link.color-primary[disabled] span,
.ck-link.variant-text.color-primary[disabled],
.ck-link.variant-text.color-primary[disabled] span {
  color: rgba(var(--primary-color-rgb), 0.5) !important;
}


/*
  Secondary
  ========================================================================== */
.ck-link.variant-primary.color-secondary {
  background: var(--secondary-dark-color);
  border: 1px solid var(--secondary-dark-color);
}
.ck-link.variant-primary.color-secondary,
.ck-link.variant-primary.color-secondary span {
  color: #fff;
}

/* Active */ /* Hover */
.ck-link.variant-primary.color-secondary:not([disabled]):focus,
.ck-link.variant-primary.color-secondary:not([disabled]):hover,
.ck-link.variant-primary.color-secondary:not([disabled]):active {
  background-color: var(--secondary-color) !important;
  color: #fff !important;
}
.ck-link.variant-primary.color-secondary:not([disabled]):focus span,
.ck-link.variant-primary.color-secondary:not([disabled]):hover span,
.ck-link.variant-primary.color-secondary:not([disabled]):active span {
  color: #fff !important;
}

/* Disabled */
.ck-link.variant-primary.color-secondary[disabled] {
  border-color: var(--black-20);
  background: var(--black-20);
  color: #fff;
}
.ck-link.variant-primary.color-secondary[disabled] span {
  color: #fff;
}

/*  
  secondary color - primary button - dashed
  ========================================================================== */
.ck-link.variant-secondary.color-secondary {
  background: transparent;
  border: 1px solid var(--secondary-dark-color);
}
.ck-link.variant-dashed.color-secondary {
  background-color: transparent;
  border: 1px dashed var(--secondary-dark-color);
}
.ck-link.variant-secondary.color-secondary,
.ck-link.variant-secondary.color-secondary span,
.ck-link.variant-dashed.color-secondary,
.ck-link.variant-dashed.color-secondary span {
  color: var(--secondary-dark-color);
}

/* Active */ /* Hover */
.ck-link.variant-secondary.color-secondary:not([disabled]):focus,
.ck-link.variant-dashed.color-secondary:not([disabled]):focus,
.ck-link.variant-secondary.color-secondary:not([disabled]):hover,
.ck-link.variant-secondary.color-secondary:not([disabled]):active,
.ck-link.variant-dashed.color-secondary:not([disabled]):hover,
.ck-link.variant-dashed.color-secondary:not([disabled]):active {
  border-color: var(--secondary-color) !important;
  color: var(--secondary-color) !important;
}
.ck-link.variant-secondary.color-secondary:not([disabled]):focus span,
.ck-link.variant-dashed.color-secondary:not([disabled]):focus span,
.ck-link.variant-secondary.color-secondary:not([disabled]):hover span,
.ck-link.variant-secondary.color-secondary:not([disabled]):active span,
.ck-link.variant-dashed.color-secondary:not([disabled]):hover span,
.ck-link.variant-dashed.color-secondary:not([disabled]):active span {
  color: var(--secondary-color) !important;
}

/* Disabled */
.ck-link.variant-secondary.color-secondary[disabled],
.ck-link.variant-dashed.color-secondary[disabled] {
  border-color: var(--black-20);
  color: var(--black-20);
}
.ck-link.variant-secondary.color-secondary[disabled] span,
.ck-link.variant-dashed.color-secondary[disabled] span {
  color: var(--black-20);
}

/*
  Secondary color - link button  - text button
  ========================================================================== */
.ck-link.variant-link.color-secondary,
.ck-link.variant-link.color-secondary span,
.ck-link.variant-text.color-secondary,
.ck-link.variant-text.color-secondary span {
  color: var(--secondary-dark-color);
}

/* Active */ /* Hover */
.ck-link.variant-link.color-secondary:not([disabled]):focus,
.ck-link.variant-link.color-secondary:not([disabled]):focus span,
.ck-link.variant-link.color-secondary:not([disabled]):hover,
.ck-link.variant-link.color-secondary:not([disabled]):hover span,
.ck-link.variant-link.color-secondary:not([disabled]):active,
.ck-link.variant-link.color-secondary:not([disabled]):active span,
.ck-link.variant-text.color-secondary:not([disabled]):focus,
.ck-link.variant-text.color-secondary:not([disabled]):focus span,
.ck-link.variant-text.color-secondary:not([disabled]):hover,
.ck-link.variant-text.color-secondary:not([disabled]):hover span,
.ck-link.variant-text.color-secondary:not([disabled]):active,
.ck-link.variant-text.color-secondary:not([disabled]):active span {
  color: var(--secondary-color) !important;
}

/* Disabled */
.ck-link.variant-link.color-secondary[disabled],
.ck-link.variant-link.color-secondary[disabled] span,
.ck-link.variant-text.color-secondary[disabled],
.ck-link.variant-text.color-secondary[disabled] span {
  color: var(--black-20);
}

/*
  Danger
  ========================================================================== */
.ck-link.variant-primary.color-danger {
  background: var(--error-color);
  border: 1px solid var(--error-color);
}
.ck-link.variant-primary.color-danger,
.ck-link.variant-primary.color-danger span {
  color: #fff;
}

/* Active */ /* Hover */
.ck-link.variant-primary.color-danger:not([disabled]):focus,
.ck-link.variant-primary.color-danger:not([disabled]):hover,
.ck-link.variant-primary.color-danger:not([disabled]):active {
  background: var(--error-dark-color) !important;
  border-color: var(--error-dark-color) !important;
  color: #fff !important;
}
.ck-link.variant-primary.color-danger:not([disabled]):focus span,
.ck-link.variant-primary.color-danger:not([disabled]):hover span,
.ck-link.variant-primary.color-danger:not([disabled]):active span {
  color: #fff !important;
}

/* Disabled */
.ck-link.variant-primary.color-danger[disabled] {
  border-color: var(--black-20);
  background: var(--black-20);
  color: #fff;
}
.ck-link.variant-primary.color-danger[disabled] span {
  color: #fff;
}

/*
  secondary color - primary button - dashed
  ========================================================================== */
.ck-link.variant-secondary.color-danger {
  background: transparent;
  border: 1px solid var(--error-color);
  color: var(--error-color);
}
.ck-link.variant-dashed.color-danger {
  background-color: transparent;
  border: 1px dashed var(--error-color);
  color: var(--error-color);
}
.ck-link.variant-secondary.color-danger span,
.ck-link.variant-dashed.color-danger span {
  color: var(--error-color);
}
  
/* Active */ /* Hover */
.ck-link.variant-secondary.color-danger:not([disabled]):focus,
.ck-link.variant-dashed.color-danger:not([disabled]):focus,
.ck-link.variant-secondary.color-danger:not([disabled]):hover,
.ck-link.variant-secondary.color-danger:not([disabled]):active,
.ck-link.variant-dashed.color-danger:not([disabled]):hover,
.ck-link.variant-dashed.color-danger:not([disabled]):active {
  border-color: var(--error-dark-color) !important;
  color: var(--error-dark-color) !important;
}
.ck-link.variant-secondary.color-danger:not([disabled]):focus span,
.ck-link.variant-dashed.color-danger:not([disabled]):focus span,
.ck-link.variant-secondary.color-danger:not([disabled]):hover span,
.ck-link.variant-secondary.color-danger:not([disabled]):active span,
.ck-link.variant-dashed.color-danger:not([disabled]):hover span,
.ck-link.variant-dashed.color-danger:not([disabled]):active span {
  color: var(--error-dark-color) !important;
}

/* Disabled */
.ck-link.variant-secondary.color-danger[disabled],
.ck-link.variant-dashed.color-danger[disabled] {
  border-color: var(--black-20);
  color: var(--black-20);
}
.ck-link.variant-secondary.color-danger[disabled] span,
.ck-link.variant-dashed.color-danger[disabled] span {
  color: var(--black-20);
}
  
  /*
    danger color - link button  - text button
    ========================================================================== */
.ck-link.variant-link.color-danger,
.ck-link.variant-link.color-danger span,
.ck-link.variant-text.color-danger,
.ck-link.variant-text.color-danger span {
  color: var(--error-color);
}

/* Active */ /* Hover */
.ck-link.variant-link.color-danger:not([disabled]):focus,
.ck-link.variant-link.color-danger:not([disabled]):hover,
.ck-link.variant-link.color-danger:not([disabled]):active,
.ck-link.variant-link.color-danger:not([disabled]):focus span,
.ck-link.variant-link.color-danger:not([disabled]):hover span,
.ck-link.variant-link.color-danger:not([disabled]):active span,
.ck-link.variant-text.color-danger:not([disabled]):focus,
.ck-link.variant-text.color-danger:not([disabled]):hover,
.ck-link.variant-text.color-danger:not([disabled]):active 
.ck-link.variant-text.color-danger:not([disabled]):focus span,
.ck-link.variant-text.color-danger:not([disabled]):hover span,
.ck-link.variant-text.color-danger:not([disabled]):active span {
  color: var(--error-dark-color) !important;
}

/* Disabled */
.ck-link.variant-link.color-danger[disabled],
.ck-link.variant-link.color-danger[disabled] span,
.ck-link.variant-text.color-danger[disabled],
.ck-link.variant-text.color-danger[disabled] span {
  color: var(--black-20);
}

    
/*
white
========================================================================== */
.ck-link.variant-primary.color-white {
  background: #fff;
  border: 1px solid #fff;
  color: var(--primary-color);
}
.ck-link.variant-primary.color-white span {
  color: var(--primary-color);
}

/* Active */ /* Hover */
.ck-link.variant-primary.color-white:not([disabled]):focus,
.ck-link.variant-primary.color-white:not([disabled]):hover,
.ck-link.variant-primary.color-white:not([disabled]):active {
  background: rgba(255, 255, 255, 0.8) !important;
  border-color: rgba(255, 255, 255, 0.8) !important;
  color: var(--primary-color) !important;
}
.ck-link.variant-primary.color-white:not([disabled]):focus span,
.ck-link.variant-primary.color-white:not([disabled]):hover span,
.ck-link.variant-primary.color-white:not([disabled]):active  span{
  color: var(--primary-color) !important;
}

/* Disabled */
.ck-link.variant-primary.color-white[disabled] {
  border-color: var(--black-20);
  background: var(--black-20);
  color: #fff;
}
.ck-link.variant-primary.color-white[disabled] span {
  color: #fff;
}


/*
  white color - primary button - dashed
  ========================================================================== */
.ck-link.variant-secondary.color-white {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
}
.ck-link.variant-dashed.color-white {
  background-color: transparent;
  border: 1px dashed #fff;
  color: #fff;
}
.ck-link.variant-secondary.color-white span,
.ck-link.variant-dashed.color-white span {
  color: #fff;
}
  
/* Active */ /* Hover */
.ck-link.variant-secondary.color-white:not([disabled]):focus,
.ck-link.variant-dashed.color-white:not([disabled]):focus,
.ck-link.variant-secondary.color-white:not([disabled]):hover,
.ck-link.variant-secondary.color-white:not([disabled]):active,
.ck-link.variant-dashed.color-white:not([disabled]):hover,
.ck-link.variant-dashed.color-white:not([disabled]):active {
  border-color: rgba(255, 255, 255, 0.8) !important;
  color: rgba(255, 255, 255, 0.8) !important;
}
.ck-link.variant-secondary.color-white:not([disabled]):focus span,
.ck-link.variant-dashed.color-white:not([disabled]):focus span,
.ck-link.variant-secondary.color-white:not([disabled]):hover span,
.ck-link.variant-secondary.color-white:not([disabled]):active span,
.ck-link.variant-dashed.color-white:not([disabled]):hover span,
.ck-link.variant-dashed.color-white:not([disabled]):active span {
  color: rgba(255, 255, 255, 0.8) !important;
}

/* Disabled */
.ck-link.variant-secondary.color-white[disabled],
.ck-link.variant-dashed.color-white[disabled] {
  border-color: var(--black-20);
  color: var(--black-20);
}
.ck-link.variant-secondary.color-white[disabled] span,
.ck-link.variant-dashed.color-white[disabled] span {
  color: var(--black-20);
}


/*
  white color - link button  - text button
  ========================================================================== */
.ck-link.variant-link.color-white,
.ck-link.variant-link.color-white span,
.ck-link.variant-text.color-white,
.ck-link.variant-text.color-white span {
  color: #fff;
}

/* Active */ /* Hover */
.ck-link.variant-link.color-white:not([disabled]):focus,
.ck-link.variant-link.color-white:not([disabled]):hover,
.ck-link.variant-link.color-white:not([disabled]):active,
.ck-link.variant-link.color-white:not([disabled]):focus span,
.ck-link.variant-link.color-white:not([disabled]):hover span,
.ck-link.variant-link.color-white:not([disabled]):active span,
.ck-link.variant-text.color-white:not([disabled]):focus,
.ck-link.variant-text.color-white:not([disabled]):hover,
.ck-link.variant-text.color-white:not([disabled]):active
.ck-link.variant-text.color-white:not([disabled]):focus span,
.ck-link.variant-text.color-white:not([disabled]):hover span,
.ck-link.variant-text.color-white:not([disabled]):active span {
  color: rgba(255, 255, 255, 0.8) !important;
}

/* Disabled */
.ck-link.variant-link.color-white[disabled],
.ck-link.variant-link.color-white[disabled] span,
.ck-link.variant-text.color-white[disabled],
.ck-link.variant-text.color-white[disabled] span {
  color: var(--black-20);
}


/* ==========================================================================
  mariner color
  ========================================================================== */
/*
  mariner color - default button 
  ========================================================================== */
.ck-link.variant-primary.color-mariner {
  background: var(--mariner-color);
  border: 1px solid var(--mariner-color);
  color: #fff;
}
.ck-link.variant-primary.color-mariner span {
  color: #fff;
}

/* Active *//* Hover */
.ck-link.variant-primary.color-mariner:not([disabled]):focus,
.ck-link.variant-primary.color-mariner:not([disabled]):hover,
.ck-link.variant-primary.color-mariner:not([disabled]):active {
  background: var(--mariner-dark-color) !important;
  color: #fff !important;
}
.ck-link.variant-primary.color-mariner:not([disabled]):focus span,
.ck-link.variant-primary.color-mariner:not([disabled]):hover span,
.ck-link.variant-primary.color-mariner:not([disabled]):active span {
  color: #fff !important;
}

/* Disabled */
.ck-link.variant-primary.color-mariner[disabled] {
  border-color: var(--black-20);
  background: var(--black-20);
  color: #fff;
}
.ck-link.variant-primary.color-mariner[disabled] span {
  color: #fff;
}

/*
mariner color - mariner button - dashed
========================================================================== */
.ck-link.variant-secondary.color-mariner {
  background: transparent;
  border: 1px solid var(--mariner-color);
  color: var(--mariner-color);
}
.ck-link.variant-dashed.color-mariner {
  background: transparent;
  border: 1px dashed var(--mariner-color);
  color: var(--mariner-color);
}
.ck-link.variant-secondary.color-mariner span,
.ck-link.variant-dashed.color-mariner span {
  color: var(--mariner-color);
}
  
/* Active *//* Hover */
.ck-link.variant-secondary.color-mariner:not([disabled]):focus,
.ck-link.variant-dashed.color-mariner:not([disabled]):focus,
.ck-link.variant-secondary.color-mariner:not([disabled]):hover,
.ck-link.variant-secondary.color-mariner:not([disabled]):active,
.ck-link.variant-dashed.color-mariner:not([disabled]):hover,
.ck-link.variant-dashed.color-mariner:not([disabled]):active{
  border-color: var(--mariner-dark-color) !important;
  color: var(--mariner-dark-color) !important;
}
.ck-link.variant-secondary.color-mariner:not([disabled]):focus span,
.ck-link.variant-dashed.color-mariner:not([disabled]):focus span,
.ck-link.variant-secondary.color-mariner:not([disabled]):hover span,
.ck-link.variant-secondary.color-mariner:not([disabled]):active span,
.ck-link.variant-dashed.color-mariner:not([disabled]):hover span,
.ck-link.variant-dashed.color-mariner:not([disabled]):active span {
  color: var(--mariner-dark-color) !important;
}

/* Disabled */
.ck-link.variant-secondary.color-mariner[disabled],
.ck-link.variant-dashed.color-mariner[disabled] {
  border-color: var(--black-20);
  color: var(--black-20);
}
.ck-link.variant-secondary.color-mariner[disabled] span,
.ck-link.variant-dashed.color-mariner[disabled] span {
  color: var(--black-20);
}
/*
  Mariner color - link button  -  - text button 
  ========================================================================== */
.ck-link.variant-link.color-mariner,
.ck-link.variant-link.color-mariner span,
.ck-link.variant-text.color-mariner,
.ck-link.variant-text.color-mariner span {
  color: var(--mariner-color);
}
  
/* Active *//* Hover */
.ck-link.variant-link.color-mariner:not([disabled]):focus,
.ck-link.variant-link.color-mariner:not([disabled]):focus span,
.ck-link.variant-link.color-mariner:not([disabled]):hover,
.ck-link.variant-link.color-mariner:not([disabled]):hover span,
.ck-link.variant-link.color-mariner:not([disabled]):active,
.ck-link.variant-link.color-mariner:not([disabled]):active span,
.ck-link.variant-text.color-mariner:not([disabled]):focus,
.ck-link.variant-text.color-mariner:not([disabled]):focus span,
.ck-link.variant-text.color-mariner:not([disabled]):hover,
.ck-link.variant-text.color-mariner:not([disabled]):hover span,
.ck-link.variant-text.color-mariner:not([disabled]):active,
.ck-link.variant-text.color-mariner:not([disabled]):active span {
  color: var(--mariner-dark-color) !important;
}
/* Disabled */
.ck-link.variant-link.color-mariner[disabled],
.ck-link.variant-link.color-mariner[disabled] span,
.ck-link.variant-text.color-mariner[disabled],
.ck-link.variant-text.color-mariner[disabled] span {
  color: var(--black-20);
}
