.ck-empty-img-large {
  width: 220px !important;
  height: 220px !important;
}

.ck-empty-img-small {
  width: 180px !important;
  height: 180px !important;
}

@media only screen and (max-width: 992px) {
  .ck-empty-img-large {
    width: 200px !important;
    height: 200px !important;
  }
}

.ck-empty-img .ck-empty-img-container {
  width: 180px;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  margin: auto;
}

.ck-empty-img .ck-empty-img-container .ck-empty-img-icon {
  padding-top: 10px;
  color: var(--primary-color);
}

.ck-empty-img .ck-empty-img-container .primary-color {
  color: var(--primary-color);
}

.ck-empty-img .ck-empty-img-container .bold {
  font-weight: 600;
}

.ck-empty-img .ck-empty-img-container .medium-font {
  font-size: 16px;
}

.ck-empty-img .ck-empty-img-container .gray-color {
  color: var(--gray-500);
}

.ck-empty-img .ck-empty-img-container .small-font {
  font-size: 12px;
}

.ck-empty-img .ck-empty-img-container .zero-margin {
  margin: 0;
}
