.ck-input input {
  width: 100%;
  color: var(--gray);
  font-size: 16px;
}
.ck-input .ant-form-item-row {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: stretch;
  width: 100%;
}
.ck-input .ant-form-item-row .ant-col {
  width: 100%;
  text-align: left;
}
.ck-input .ant-form-item-explain div {
  color: #666;
  font-size: 12px;
  line-height: 15px;
  color: var(--gray-450);
}
.ck-input .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  margin-right: 0;
  color: var(--text-color);
}

/* ==========================================================================
  Default
  ========================================================================== */
.ck-input.default {

}
.ck-input.default label,
.ck-input.default .ant-form-item-extra {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 600 !important;
  line-height: 26px;
  height: 26px;
}
.ck-input.default input {
  padding-left: 0;
  padding-right: 0;
  border-radius: 0 !important;
  border: 0;
  border-bottom: 1px solid var(--text-color);
}
.ck-input.default input::placeholder {
  text-align: left !important;
  color: var(--gray-300) !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px; 
}
.ck-input.default .ant-input-affix-wrapper {
  padding-left: 0;
  padding-right: 0;
  border: 0;
  border-bottom: 1px solid var(--text-color);
  border-radius: 0px !important;
}
.ck-input.default .ant-input-affix-wrapper input {
  border: 0 !important;
}


/* ==========================================================================
  Rounded
  ========================================================================== */
.ck-input.rounded {
  color: #fff;
}
.ck-input.rounded label,
.ck-input.rounded .ant-form-item-extra {
  color: #fff;
  font-weight: 600 !important;
  font-weight: 600;
  line-height: 26px;
  height: 26px;
}
.ck-input.rounded input {
  width: 100%;
  height: 44px;
  border-radius: 8px;
  background: var(--white);
  text-align: left;
}
.ck-input.rounded input::placeholder {
  text-align: left !important;
  color: var(--gray) !important;
}
.ck-input.rounded .ant-input-affix-wrapper {
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 8px;
  overflow: hidden;
}
.ck-input.rounded .ant-input-clear-icon,
.ck-input.rounded .ant-input-clear-icon svg {
  height: 18px;
  width: auto;
}
.ck-input.rounded .ant-form-item-explain {
  margin-top: 5px;
  color: var(--white);
  font-size: 16px;
  line-height: 20px;
}
.ck-input.rounded .ant-input-suffix svg {
  height: 18px !important;
  width: auto !important;
}
.ck-input.rounded .ant-form-item-explain div,
.ck-input.rounded .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  color: #fff;
}
